// import packages
import { motion } from 'framer-motion';
import { HiThumbUp, HiThumbDown } from 'react-icons/hi';

// import components
import { Navbar } from '../components/navbar/index.jsx';
import { Footer } from '../components/footer/index.jsx';

// import assets
import oklo_ta_001 from '../../public/assets/revised_oklo.png';
import oklo_ta_001_mobile from '../../public/assets/revised_oklo_mobile.png';
import clean from '../../public/assets/modern_005.png';

// import validation
import { useFeedback } from '../hooks/useFeedback.jsx';

export const TAPage = () => {
	const { submitFeedback, feedback } = useFeedback(); // API call hook

	// Feedback Handler
	const handleFeedback = async action => {
		try {
			return await submitFeedback({ action });
		} catch (error) {
			console.error('Feedback submission failed:', error);
		}
	};

	// Main Content (Accessible to Verified Users)
	return (
		<div className="text-luif-text">
			<Navbar />
			{/* Hero Section */}
			<div
				className="relative h-full py-40 flex items-center justify-center text-white px-4"
				style={{
					backgroundImage: `url(${clean})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundAttachment: window.innerWidth > 768 ? 'fixed' : 'scroll',
				}}>
				<div className="absolute inset-0 bg-gray-800/40"></div>
				<div className="absolute h-4/6 w-full sm:w-4/6 sm:h-4/6 bg-gray-700 z-10 opacity-55 rounded-xl px-4 drop-shadow-lg"></div>
				<div className="relative z-20 text-center space-y-4 opacity-100 py-12 sm:px-12">
					<motion.h1 initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8 }} className="text-5xl font-bold mb-4">
						Exploring OKLO Inc. Chart Patterns
					</motion.h1>
					<motion.p
						initial={{ y: 20, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ duration: 0.8 }}
						className="text-lg max-w-2xl mx-auto px-2 subText tracking-wide text-gray-400">
						A data-driven analysis of key trends shaping OKLO Inc.'s price movements.
					</motion.p>
					<motion.p
						initial={{ y: 20, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ duration: 0.8 }}
						className="text-lg max-w-2xl mx-auto px-2 subText tracking-wide text-gray-400">
						As of <span className="text-green-400 font-semibold">January 7, 2025</span>, this breakdown examines OKLO Inc.'s recent chart patterns, uncovering
						significant insights into price action. This analysis is designed to educate and empower traders and investors with actionable information.
					</motion.p>
				</div>
			</div>

			{/* Chart Section */}
			<div className="bg-zinc-50">
				<div className="text-left grid grid-cols-1 sm:grid-cols-8 mx-auto px-2 sm:px-0">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-6 py-8 sm:py-12 space-y-4">
						<div className="text-white px-2 sm:px-0">
							{/* Mobile Image (visible on small screens) */}
							<div className="flex justify-center">
								<motion.img
									src={oklo_ta_001_mobile}
									alt="Annotated Chart for Mobile"
									className="rounded-lg drop-shadow-lg block sm:hidden h-full w-auto" // Hidden on sm and larger screens
									initial={{ opacity: 0, y: 20 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ duration: 0.5 }}
								/>
							</div>

							{/* Desktop/Tablet Image (visible on larger screens) */}
							<motion.img
								src={oklo_ta_001}
								alt="Annotated Chart"
								className="rounded-lg drop-shadow-lg hidden sm:block" // Hidden on xs screens, visible on sm and larger
								initial={{ opacity: 0, y: 20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.5 }}
							/>
							<p className="text-gray-500 mt-4 text-center text-sm italic">Annotated chart featuring numbered insights for quick understanding.</p>
						</div>
					</div>
					<div></div>
				</div>
			</div>

			{/* Insights Section */}
			<div className="bg-gray-800">
				<div className="text-left grid grid-cols-1 sm:grid-cols-8 mx-auto px-2 sm:px-0">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-6 py-12 space-y-4">
						<div className="text-white px-2 sm:px-20 space-y-4">
							<motion.div
								className="bg-gray-700 rounded-lg p-6 shadow-lg"
								initial={{ opacity: 0, x: -20 }}
								animate={{ opacity: 1, x: 0 }}
								transition={{ duration: 0.5, delay: 0.1 }}>
								<h2 className="text-2xl font-bold mb-2">1. Bearish Divergence Signals Weakness</h2>
								<p className="text-gray-300">
									The stock hit a high of <span className="text-green-400">$31.66</span> with RSI nearing 69, indicating overbought conditions. However, RSI’s
									lower highs suggest weakening momentum. Watch for pullbacks near <span className="text-green-400">$17.60</span>, as divergence signals potential
									challenges ahead.
								</p>
							</motion.div>

							{/* Insight Block 2 */}
							<motion.div
								className="bg-gray-700 rounded-lg p-6 shadow-lg"
								initial={{ opacity: 0, x: 20 }}
								animate={{ opacity: 1, x: 0 }}
								transition={{ duration: 0.5, delay: 0.2 }}>
								<h2 className="text-2xl font-bold mb-2">2. Rising Support Trendline Holds</h2>
								<p className="text-gray-300">
									A consistent pattern of higher lows reflects bullish sentiment. This mid-term rising support trendline showcases confidence among buyers, making
									it an area worth monitoring for future developments.
								</p>
							</motion.div>

							{/* Insight Block 3 */}
							<motion.div
								className="bg-gray-700 rounded-lg p-6 shadow-lg"
								initial={{ opacity: 0, x: -20 }}
								animate={{ opacity: 1, x: 0 }}
								transition={{ duration: 0.5, delay: 0.3 }}>
								<h2 className="text-2xl font-bold mb-2">3. Falling Wedge Breakout Confirmed</h2>
								<p className="text-gray-300">
									OKLO recently broke out of a falling wedge pattern after multiple resistance tests, supported by a surge in volume. This breakout demonstrates
									how short-term patterns can signal long-term opportunities.
								</p>
							</motion.div>

							{/* Insight Block 4 */}
							<motion.div
								className="bg-gray-700 rounded-lg p-6 shadow-lg"
								initial={{ opacity: 0, x: 20 }}
								animate={{ opacity: 1, x: 0 }}
								transition={{ duration: 0.5, delay: 0.4 }}>
								<h2 className="text-2xl font-bold mb-2">4. Long-Term Support Established</h2>
								<p className="text-gray-300">
									The former resistance at <span className="text-green-400">$11.25</span> has transitioned into a key support level. This critical long-term base
									sheds light on past price behaviors and may influence future movement.
								</p>
							</motion.div>
						</div>
					</div>
					<div></div>
				</div>
			</div>

			{/* Summary Section */}
			<div className="bg-zinc-50 text-zinc-900">
				<div className="text-left grid grid-cols-1 sm:grid-cols-8 mx-auto px-2 sm:px-0">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-6 py-16 space-y-4">
						<div className="px-2 sm:px-20 space-y-4">
							<h3 className="text-2xl font-bold mb-4">In Summary</h3>
							<p className="">Today’s analysis highlights pivotal technical patterns for OKLO Inc.:</p>
							<ul>
								<li>Bearish divergence indicating weakening momentum.</li>
								<li>A rising support trendline reflecting bullish confidence.</li>
								<li>A confirmed falling wedge breakout, signaling upward potential.</li>
								<li>
									A critical long-term support level at <span className="font-semibold tracking-wider">$11.25</span>.
								</li>
							</ul>
							<p className="">
								Key price points to watch include <span className="font-semibold tracking-wider">$17.60</span> and{' '}
								<span className="font-semibold tracking-wider">$31.66</span>, which have shown notable activity. This analysis underscores how technical tools can
								provide valuable insights into market behavior.
							</p>
						</div>
					</div>
					<div></div>
				</div>
			</div>

			{/* Feedback Section */}
			<div className="bg-gray-100 py-6 px-8 text-center">
				{!feedback.hasSubmitted ? (
					<div>
						<p className="text-gray-700 mb-4">Your feedback matters! Let us know how this breakdown resonated with you.</p>
						<div className="flex justify-center space-x-6">
							<HiThumbUp className="h-8 w-8 cursor-pointer hover:scale-110 transition-transform" onClick={() => handleFeedback('like')} />
							<HiThumbDown className="h-8 w-8 cursor-pointer hover:scale-110 transition-transform" onClick={() => handleFeedback('dislike')} />
						</div>
					</div>
				) : (
					<div className="inline-block">
						<p className="text-gray-700 font-normal tracking-normal">Thank you for your feedback! It helps us continually improve. 📈</p>
					</div>
				)}
			</div>

			{/* Disclaimer Section */}
			<div className="bg-zinc-50 text-zinc-900">
				<div className="text-left grid grid-cols-1 sm:grid-cols-8 mx-auto px-2 sm:px-0">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-6 pb-12 space-y-4">
						<div className="px-2 sm:px-20 space-y-4">
							<div className="bg-gray-700 text-zinc-50 py-8 px-8 rounded-lg">
								<p className="">
									This analysis is for educational and informational purposes only. It is not financial advice, and no recommendations are being made. Always
									conduct your own research.
								</p>
							</div>
						</div>
					</div>
					<div></div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
