import React, { useEffect, useRef, useState } from 'react';
import { usePodcastContext } from '../../context/audio_context.js';
import { motion, AnimatePresence } from 'framer-motion';
import { trackPodcastPlay } from '../../hooks/useAnalytics.jsx';

export const PodcastPlayer = ({ id, title, audioSrc, imageSrc, podcastName }) => {
	const { currentPodcastId, setCurrentPodcastId, currentAudioRef, setCurrentAudioRef } = usePodcastContext();
	const audioRef = useRef(null);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [isSeeking, setIsSeeking] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [elapsedMinutes, setElapsedMinutes] = useState(0);
	const intervalRef = useRef(null);

	useEffect(() => {
		const audio = audioRef.current;

		const updateTime = () => {
			if (!isSeeking) setCurrentTime(audio.currentTime);
		};
		const updateDuration = () => setDuration(audio.duration);

		audio.addEventListener('timeupdate', updateTime);
		audio.addEventListener('loadedmetadata', updateDuration);

		return () => {
			audio.removeEventListener('timeupdate', updateTime);
			audio.removeEventListener('loadedmetadata', updateDuration);
		};
	}, [isSeeking]);

	// Start a timer to track minutes played
	const startTrackingMinutes = () => {
		if (!intervalRef.current) {
			intervalRef.current = setInterval(() => {
				setElapsedMinutes(prev => prev + 1);
				// console.log(`Tracking 1 minute played for ${podcastName}`);
				trackPodcastPlay(podcastName, 1, false); // API call for every minute (does not increment play count)
			}, 60000);
		}
	};

	// Stop the timer
	const stopTrackingMinutes = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	};

	const handleTogglePlayPause = () => {
		if (currentPodcastId !== id) {
			// Pause the currently playing podcast, if any
			if (currentAudioRef) {
				currentAudioRef.pause();
				stopTrackingMinutes();
			}

			// Set the new podcast as the current one
			setCurrentPodcastId(id);
			setCurrentAudioRef(audioRef.current);

			audioRef.current.play();
			setIsPlaying(true);
			trackPodcastPlay(podcastName, 0, true); // Increment play count only when first played
			startTrackingMinutes();
		} else {
			if (isPlaying) {
				// Pause the podcast
				audioRef.current.pause();
				setIsPlaying(false);
				stopTrackingMinutes();
			} else {
				// Resume playing the podcast
				audioRef.current.play();
				setIsPlaying(true);
				trackPodcastPlay(podcastName, 0, true); // Increment play count only when resumed
				startTrackingMinutes();
			}
		}
	};

	const handleSeek = e => {
		const newTime = (e.target.value / 100) * duration;
		setCurrentTime(newTime);
		audioRef.current.currentTime = newTime;
		setIsSeeking(false);
	};

	const handleSeekStart = () => {
		setIsSeeking(true);
	};

	useEffect(() => {
		// Cleanup interval when the component unmounts
		return () => {
			stopTrackingMinutes();
		};
	}, []);

	return (
		<div className="audio-player bg-zinc-800 rounded-xl overflow-hidden shadow-lg">
			{imageSrc && (
				<div className="image-container">
					<img src={imageSrc} alt={title} className="w-full max-h-40 object-cover" />
				</div>
			)}
			<div className="controls p-4 text-white">
				<audio ref={audioRef} src={audioSrc} preload="metadata" />
				{title && <h2 className="text-lg font-semibold">{title}</h2>}
				<p className="text-sm text-zinc-400">LVLUPINFINANCE</p>

				<div className="mt-4 flex items-center gap-4">
					<button onClick={handleTogglePlayPause} className="text-3xl flex-shrink-0 focus:outline-none">
						{isPlaying && currentPodcastId === id ? (
							<AnimatePresence mode="wait">
								<motion.svg
									key="pause-icon"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="currentColor"
									initial={{ scale: 0.8, opacity: 0 }}
									animate={{ scale: 1, opacity: 1 }}
									exit={{ scale: 0.8, opacity: 0 }}
									transition={{ duration: 0.2 }}>
									<rect x="6" y="4" width="4" height="16"></rect>
									<rect x="14" y="4" width="4" height="16"></rect>
								</motion.svg>
							</AnimatePresence>
						) : (
							<AnimatePresence mode="wait">
								<motion.svg
									key="play-icon"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="currentColor"
									initial={{ scale: 0.8, opacity: 0 }}
									animate={{ scale: 1, opacity: 1 }}
									exit={{ scale: 0.8, opacity: 0 }}
									transition={{ duration: 0.2 }}>
									<polygon points="5,3 19,12 5,21"></polygon>
								</motion.svg>
							</AnimatePresence>
						)}
					</button>

					<div className="flex-grow">
						<div>
							<input
								className="w-full h-2 bg-gray-600 rounded-lg appearance-none"
								type="range"
								min="0"
								max="100"
								value={(currentTime / duration) * 100 || 0}
								onChange={handleSeek}
								onMouseDown={handleSeekStart}
								onMouseUp={handleSeek}
							/>
						</div>
						<div className="text-xs text-zinc-400 flex justify-between mt-1">
							<span>{formatTime(currentTime)}</span>
							<span>-{formatTime(duration)}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const formatTime = time => {
	if (isNaN(time)) return '0:00';
	const minutes = Math.floor(time / 60);
	const seconds = Math.floor(time % 60)
		.toString()
		.padStart(2, '0');
	return `${minutes}:${seconds}`;
};
