// import packages
import { useState } from 'react';
import { motion } from 'framer-motion';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FaFileAlt, FaExclamationTriangle, FaChartBar, FaCoins, FaBook, FaMoneyCheck, FaIndustry, FaBolt, FaBuilding } from 'react-icons/fa';

// import components
import { Navbar } from '../navbar/index.jsx';
import { Footer } from '../footer/index.jsx';
import { FormS1Quiz } from '../quizzes/form_s1.jsx';
import { Feedback } from '../feedback/index.jsx';

// import assers
import clean from '../../../public/assets/modern_006.png';

export const FormS1 = () => {
	const [openIndex, setOpenIndex] = useState(null);
	const [isOpen, setIsOpen] = useState(null);

	const staggerContainer = {
		hidden: { opacity: 1 },
		visible: {
			opacity: 1,
			transition: {
				staggerChildren: 0.3,
			},
		},
	};

	const accordionVariant = {
		hidden: { height: 0, opacity: 0 },
		visible: {
			height: 'auto',
			opacity: 1,
			transition: { duration: 0.4, ease: 'easeOut' },
		},
	};

	const fadeIn = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
	};

	const faqs = [
		{ question: 'What is Form S-1?', answer: 'Form S-1 is the SEC filing companies must submit before they sell shares to the public.' },
		{ question: 'Is Form S-1 only for IPOs?', answer: 'Nope! Companies also file a new S-1 if they want to raise additional funds after already being public.' },
		{ question: 'Why should I care about Form S-1?', answer: 'It’s your window into the company’s financial health, growth strategy, and risks.' },
		{ question: 'What should I focus on in the Form S-1?', answer: "Sections like 'Use of Proceeds,' 'Risk Factors,' and 'Business Overview.' " },
		{ question: 'How do I know if a company is being transparent?', answer: "Vague language in sections like 'Use of Proceeds' is a red flag." },
	];

	const sectionData = [
		{
			icon: <FaBook className="text-indigo-500 text-xl" />,
			title: 'General Corporate Purposes',
			say: 'We plan to use the funds for general corporate purposes.',
			means: 'This could mean anything, from paying down debt to funding expansion. It’s purposely vague, so always check if they give more details elsewhere in the form.',
			decode: 'Look for clues in sections like \u201cBusiness Overview\u201d or \u201cManagement\u2019s Discussion.\u201d If the company isn’t specific, it might indicate they don’t have a clear strategy or are hesitant to reveal too much.',
		},
		{
			icon: <FaMoneyCheck className="text-green-500 text-xl" />,
			title: 'Working Capital',
			say: 'A portion of the proceeds will be used for working capital.',
			means: 'This typically means the company needs cash to cover day-to-day expenses like payroll, inventory, and rent.',
			decode: 'If a large portion of proceeds is going toward working capital, it could mean the company is struggling to maintain its operations. Compare this with their current cash reserves to get a better sense of their financial health.',
		},
		{
			icon: <FaIndustry className="text-yellow-500 text-xl" />,
			title: 'Strategic Investments',
			say: 'We plan to make strategic investments with the proceeds.',
			means: 'They want to grow, whether through acquiring new technology, expanding into new markets, or hiring more staff.',
			decode: 'Check if these investments align with their core business. If they’re investing in areas they’re unfamiliar with, that could be risky.',
		},
		{
			icon: <FaBolt className="text-red-500 text-xl" />,
			title: 'Debt Repayment',
			say: 'Proceeds will be used to repay existing indebtedness.',
			means: 'They’re using the money to pay off debt.',
			decode: 'While paying off debt is often a smart move, if too much of the proceeds are going toward this, it could indicate the company is over-leveraged. Look for the interest rates on that debt to assess whether this is a positive step or a warning sign.',
		},
		{
			icon: <FaBuilding className="text-blue-500 text-xl" />,
			title: 'Acquisitions',
			say: 'We may use a portion of the proceeds for potential acquisitions.',
			means: 'They plan to buy other companies or assets to help them grow.',
			decode: 'Ask yourself—does the company have a good track record with acquisitions? Mergers and acquisitions (M&A) can boost growth, but they can also be risky if not executed well.',
		},
	];

	const importantSectionsData = [
		{
			icon: <FaFileAlt className="text-blue-500 text-3xl" />,
			title: 'Business Overview',
			description: 'The company outlines what they do, their market, and their business model.',
		},
		{
			icon: <FaExclamationTriangle className="text-yellow-500 text-3xl" />,
			title: 'Risk Factors',
			description: 'Lists potential risks—valuable insights often hidden here.',
		},
		{
			icon: <FaChartBar className="text-green-500 text-3xl" />,
			title: 'Financial Statements',
			description: 'Includes income statement, balance sheet, and cash flow.',
		},
		{
			icon: <FaCoins className="text-purple-500 text-3xl" />,
			title: 'Use of Proceeds',
			description: 'Explains how the company plans to use raised funds.',
		},
	];

	const toggleFAQ = index => {
		setOpenIndex(index === openIndex ? null : index);
	};

	const toggleSection = index => {
		setIsOpen(index === isOpen ? null : index);
	};

	return (
		<>
			<Navbar />
			{/* Hero Section */}
			<div
				className="relative h-full py-40 flex items-center justify-center text-white px-4"
				style={{
					backgroundImage: `url(${clean})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundAttachment: window.innerWidth > 768 ? 'fixed' : 'scroll',
				}}>
				<div className="absolute inset-0 bg-gray-900/50"></div>
				<div className="absolute h-4/6 w-full sm:w-4/6 sm:h-4/6 bg-gray-700 z-10 opacity-60 rounded-xl px-4 drop-shadow-lg"></div>
				<div className="relative z-20 text-center space-y-4 opacity-100 py-12 sm:px-12">
					<motion.h1 initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8 }} className="text-5xl font-bold mb-4">
						Introduction to Form S-1
					</motion.h1>
					<motion.p
						initial={{ y: 20, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ duration: 0.8 }}
						className="text-lg max-w-2xl mx-auto px-2 subText tracking-wide text-gray-400">
						<span className="font-bold text-green-500">Form S-1</span> is the gateway for companies looking to go public or raise capital. It’s where they share their
						story, financials, and plans for the funds they aim to raise.
					</motion.p>
					<motion.p
						initial={{ y: 20, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ duration: 0.8 }}
						className="text-lg max-w-2xl mx-auto px-2 subText tracking-wide text-gray-400">
						Reading it can seem daunting, but this guide breaks it down—especially the “Use of Proceeds” section, which reveals how companies plan to use the money.
						Ready to dive in?
					</motion.p>
				</div>
			</div>

			<div className="bg-gray-50">
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-12">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4 py-12">
						<motion.h1 className="text-2xl font-bold text-gray-800" initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
							FAQs About Form S-1
						</motion.h1>
						<motion.p className="text-lg text-gray-600" initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.7 }}>
							Before we go deeper, let’s quickly answer some common questions about the form to set the stage.
						</motion.p>
						{faqs.map((faq, index) => (
							<div key={index} className="border-b border-gray-200 py-2">
								<button
									className="w-full flex justify-between items-center text-left text-gray-800 font-medium focus:outline-none"
									onClick={() => toggleFAQ(index)}>
									<span>{faq.question}</span>
									{openIndex === index ? <FiChevronUp /> : <FiChevronDown />}
								</button>
								{openIndex === index && (
									<motion.p
										className="mt-2 text-gray-600 text-sm"
										initial={{ opacity: 0, height: 0 }}
										animate={{ opacity: 1, height: 'auto' }}
										exit={{ opacity: 0, height: 0 }}>
										{faq.answer}
									</motion.p>
								)}
							</div>
						))}
					</div>
					<div></div>
				</div>
			</div>

			<div className="bg-gradient-to-t from-gray-800 to-gray-400 text-white py-12">
				<div className="max-w-7xl mx-auto px-4 sm:px-12">
					<motion.div initial={{ opacity: 0, scale: 0.95 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5 }}>
						<h1 className="text-4xl font-extrabold text-center tracking-tight">Important Sections in Form S-1</h1>
					</motion.div>

					<motion.p className="text-xl text-center text-gray-200 mt-4" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.7 }}>
						Now that you’re more familiar with what Form S-1 is and why it matters, let’s look at some of the key sections you’ll encounter:
					</motion.p>

					<div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
						{importantSectionsData.map((section, index) => (
							<motion.div
								key={index}
								className="flex items-center space-x-4 p-6 bg-white rounded-lg shadow-xl hover:shadow-2xl transition-all duration-300"
								initial={{ opacity: 0, scale: 0.9 }}
								whileInView={{ opacity: 1, scale: 1 }}
								transition={{ duration: 0.5, delay: index * 0.2 }}>
								{section.icon}
								<div>
									<p className="text-lg font-semibold text-gray-900">{section.title}</p>
									<p className="text-sm text-gray-600">{section.description}</p>
								</div>
							</motion.div>
						))}
					</div>
				</div>
			</div>

			<div className="bg-gray-50 py-12">
				<div className="max-w-4xl mx-auto px-6">
					<motion.div initial="hidden" animate="visible" variants={staggerContainer} className="space-y-6 text-left">
						<motion.h1 variants={fadeIn} className="text-4xl font-black text-gray-900 tracking-tight">
							Decoding the "Use of Proceeds" Section
						</motion.h1>
						<motion.p variants={fadeIn} className="text-lg text-gray-600 font-medium leading-relaxed">
							The “Use of Proceeds” section reveals how companies plan to spend the money raised from selling stock. Here's how to interpret it effectively.
						</motion.p>
						<motion.div variants={fadeIn} className="space-y-4">
							{sectionData.map((item, index) => (
								<motion.div key={index} className="bg-white rounded-lg shadow p-4 hover:shadow-lg transition-shadow" whileHover={{ scale: 1.02 }}>
									<motion.button
										onClick={() => toggleSection(index)}
										className="flex items-center space-x-3 cursor-pointer font-bold text-lg text-gray-800"
										initial={{ x: -20, opacity: 0 }}
										animate={{ x: 0, opacity: 1 }}
										transition={{ duration: 0.4 }}>
										<span className="text-2xl">{item.icon}</span> <span>{item.title}</span>
									</motion.button>
									{isOpen === index && (
										<motion.div variants={accordionVariant} initial="hidden" animate="visible" className="mt-2 space-y-2 text-gray-700 overflow-hidden">
											<p>
												<span className="underline font-bold">What They Say:</span> {item.say}
											</p>
											<p>
												<span className="underline font-bold">What It Means:</span> {item.means}
											</p>
											<p>
												<span className="underline font-bold">How to Decode It:</span> {item.decode}
											</p>
										</motion.div>
									)}
								</motion.div>
							))}
						</motion.div>
					</motion.div>
				</div>
			</div>

			<FormS1Quiz />

			<Feedback />

			<Footer />
		</>
	);
};
