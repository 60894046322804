// import packages
import React from 'react';
import { HiThumbUp, HiThumbDown } from 'react-icons/hi';

// import validation
import { useFeedback } from '../../hooks/useFeedback.jsx';

export const Feedback = () => {
	const { submitFeedback, feedback } = useFeedback(); // API call hook

	// Feedback Handler
	const handleFeedback = async action => {
		try {
			return await submitFeedback({ action });
		} catch (error) {
			console.error('Feedback submission failed:', error);
		}
	};

	return (
		<div>
			{/* Feedback Section */}
			<div className="bg-gray-50 py-12 px-8 text-center">
				{!feedback.hasSubmitted ? (
					<div>
						<p className="text-gray-700 mb-4">Your feedback matters! Let us know how this breakdown resonated with you.</p>
						<div className="flex justify-center space-x-6">
							<HiThumbUp className="h-8 w-8 cursor-pointer hover:scale-110 transition-transform text-gray-400" onClick={() => handleFeedback('like')} />
							<HiThumbDown className="h-8 w-8 cursor-pointer hover:scale-110 transition-transform text-gray-400" onClick={() => handleFeedback('dislike')} />
						</div>
					</div>
				) : (
					<div className="inline-block">
						<p className="text-gray-700 font-normal tracking-normal">
							Thank you for your feedback! It helps us continually improve. <span className="text-xl">📈</span>
						</p>
					</div>
				)}
			</div>
		</div>
	);
};
