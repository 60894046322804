import { motion } from 'framer-motion';

// Import assets
import analysisImage from '../../../public/assets/undraw_analytics.svg';
import teamImage from '../../../public/assets/undraw_team.svg';
import podcastListener from '../../../public/assets/undraw_podcast-listener.svg';
import clean from '../../../public/assets/modern_003.png';

export const Landing = () => {
	return (
		<div className="text-gray-900 font-sans">
			{/* Hero Section */}
			<div
				className="relative h-full py-64 flex items-center justify-center text-white"
				style={{
					backgroundImage: `url(${clean})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundAttachment: window.innerWidth > 768 ? 'fixed' : 'scroll',
				}}>
				<div className="absolute inset-0 bg-gray-900/50"></div>
				<div className="relative z-10 text-center">
					<motion.h1 initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8 }} className="text-5xl font-bold mb-4">
						Empowering Financial Growth
					</motion.h1>
					<motion.p initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8 }} className="text-lg max-w-2xl mx-auto">
						Simplify investing with insights, analysis, and actionable strategies designed for you.
					</motion.p>
					<motion.button
						whileHover={{ scale: 1.05 }}
						whileTap={{ scale: 0.95 }}
						className="mt-6 px-8 py-4 bg-white text-zinc-900 font-semibold logo rounded-lg shadow-md hover:bg-gray-100"
						onClick={() => window.location.replace('/library')}>
						Explore the Library
					</motion.button>
				</div>
			</div>

			{/* <section className="relative h-screen flex flex-col justify-center items-center text-center bg-gradient-to-b from-gray-900 to-zinc-100 text-white">
			</section> */}

			{/* Features Section */}
			<section className="py-20 px-4 bg-gray-50">
				<div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 text-center">
					{/* Podcasts Feature */}
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.8 }}
						viewport={{ once: true }}
						className="space-y-6 p-8 bg-white shadow-lg rounded-lg">
						<img src={podcastListener} alt="Podcasts" className="mx-auto w-24" loading="lazy" />
						<h3 className="text-2xl font-bold">Podcasts</h3>
						<p className="text-gray-600">Simplifying SEC filings, 10-Qs, and more, into short, insightful episodes designed for learning on the go.</p>
					</motion.div>

					{/* Trend Analysis Feature */}
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.8 }}
						viewport={{ once: true }}
						className="space-y-6 p-8 bg-white shadow-lg rounded-lg">
						<img src={analysisImage} alt="Trend Analysis" className="mx-auto w-24" loading="lazy" />
						<h3 className="text-2xl font-bold">Trend Analysis</h3>
						<p className="text-gray-600">Learn to identify patterns and market trends with case studies like Oklo Inc.</p>
					</motion.div>

					{/* Call-to-Action */}
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.8 }}
						viewport={{ once: true }}
						className="space-y-6 p-8 bg-white shadow-lg rounded-lg">
						<h3 className="text-2xl font-bold">Start Learning Today</h3>
						<p className="text-gray-600">Explore our curated library of insights and actionable strategies.</p>
						<motion.button
							whileHover={{ scale: 1.05 }}
							whileTap={{ scale: 0.95 }}
							className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700"
							onClick={() => window.location.replace('/library')}>
							Visit the Library
						</motion.button>
					</motion.div>
				</div>
			</section>

			{/* About Section */}
			<section className="py-20 px-4 bg-white">
				<div className="max-w-7xl mx-auto text-center">
					<motion.h2 initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8 }} className="text-3xl font-bold mb-8">
						About Us
					</motion.h2>
					<motion.img
						src={teamImage}
						alt="About Us"
						className="mx-auto w-full max-w-md mb-8"
						initial={{ scale: 0.8, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						transition={{ duration: 1 }}
						loading="lazy"
					/>
					<motion.p initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8 }} className="text-lg max-w-2xl mx-auto text-gray-600">
						We simplify complex financial topics to make investing more accessible. Our mission is to help you build confidence and clarity in your financial decisions.
					</motion.p>
				</div>
			</section>
		</div>
	);
};
