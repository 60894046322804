// Function to track page visits
export const trackPageVisit = async () => {
	try {
		const pageUrl = window.location.pathname;

		const response = await fetch(`${process.env.REACT_APP_ANALYTICS_URI}/luif/track-page`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ pageUrl }),
		});

		if (!response.ok) {
			console.error('Failed to track page visit');
		}
	} catch (error) {
		console.error('Error tracking page visit:', error);
	}
};

// Function to track podcast plays and minutes played
export const trackPodcastPlay = async (podcastName, minutesPlayed, isPlayEvent) => {
	// console.log('Podcast tracking parameters:', { podcastName, minutesPlayed, isPlayEvent });
	try {
		if (!podcastName) {
			console.error('Invalid podcast tracking parameters');
			return;
		}

		// If this is a play event, update the play count, otherwise only update the minutes played.
		const response = await fetch(`${process.env.REACT_APP_ANALYTICS_URI}/luif/track-podcast`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ podcastName, minutesPlayed, isPlayEvent }),
		});

		// console.log('Response status:', response.status);
		if (response.ok) {
			const responseData = await response.json();
			// console.log('Response data:', responseData);
		} else {
			console.error('Failed to track podcast play', response.status);
		}
	} catch (error) {
		console.error('Error tracking podcast play:', error);
	}
};
