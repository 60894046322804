// import packages
import React, { createContext, useState, useContext } from 'react';

// create audio context for podcast
const PodcastContext = createContext();

export const PodcastProvider = ({ children }) => {
	const [currentPodcastId, setCurrentPodcastId] = useState(null);
	const [currentAudioRef, setCurrentAudioRef] = useState(null);

	return (
		<PodcastContext.Provider
			value={{
				currentPodcastId,
				setCurrentPodcastId,
				currentAudioRef,
				setCurrentAudioRef,
			}}>
			{children}
		</PodcastContext.Provider>
	);
};

export const usePodcastContext = () => useContext(PodcastContext);
