// import packages
import { useState } from 'react';
import { motion } from 'framer-motion';
import { HiOutlineLightningBolt } from 'react-icons/hi';

export const FormS1Quiz = () => {
	const questions = [
		{
			id: 1,
			question: "A company plans to use 50% of the proceeds for 'general corporate purposes' and the rest for 'working capital.' What does this tell you?",
			options: [
				'A) They have a clear plan for expansion',
				'B) They might not be transparent about their financial situation',
				'C) They are investing in new markets',
				'D) They are repaying debt',
			],
			answer: "B) They might not be transparent. 'General corporate purposes' is vague, so always look deeper into other sections to assess their real strategy.",
		},
		{
			id: 2,
			question: "If a company states that they will use the majority of proceeds for 'strategic acquisitions,' what should you do next?",
			options: [
				'A) Assume the acquisitions will boost their business',
				'B) Investigate whether their track record with acquisitions is strong',
				'C) Ignore it, because acquisitions always work out',
				"D) Worry about the company's financial stability",
			],
			answer: 'B) Investigate their acquisition history. Successful M&A can boost growth, but it’s important to see if they have experience with this.',
		},
		{
			id: 3,
			question: "When a company says they are using proceeds for 'debt repayment,' what could be a good follow-up question?",
			options: [
				'A) What is the interest rate on the debt?',
				'B) Are they planning to take on new debt?',
				'C) How will this affect their overall expansion strategy?',
				'D) All of the above',
			],
			answer: 'D) All of the above. Debt repayment is generally good, but you’ll want to know the details to see how it impacts their future.',
		},
		{
			id: 4,
			question: "You see that a company plans to use 70% of proceeds for 'working capital.' What might this indicate?",
			options: [
				'A) They’re financially healthy and don’t need the cash',
				'B) They might be struggling to cover day-to-day expenses',
				'C) They’re preparing for rapid expansion',
				'D) They’re looking to make acquisitions',
			],
			answer: 'B) A large amount dedicated to working capital suggests they may be struggling with day-to-day expenses.',
		},
		{
			id: 5,
			question: 'A company plans to invest the proceeds in new markets. What should you verify?',
			options: [
				"A) Ensure the investments align with the company's core business",
				'B) Assume their strategy is well thought out',
				"C) Ignore this as it's normal for companies to diversify",
				'D) Focus only on their financial reports',
			],
			answer: 'A) Ensure that the investments align with the company’s core business to avoid unnecessary risk.',
		},
	];

	const [activeQuestion, setActiveQuestion] = useState(null);

	const handleToggle = id => {
		setActiveQuestion(activeQuestion === id ? null : id);
	};

	return (
		<div className="bg-gray-800 text-gray-50 py-8 sm:px-12">
			<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-12">
				<div></div>
				<div className="sm:col-start-2 sm:col-span-4 py-12">
					<div className="text-left max-w-3xl mx-auto">
						<h1 className="text-3xl font-bold tetx-gray-50 mb-4">Test Your Understanding</h1>
						<p className="text-xl font-medium text-gray-400 mb-6">
							Take these quick quizzes to reinforce your knowledge about Form S-1 and the Use of Proceeds section.
						</p>

						<div className="space-y-8">
							{questions.map(q => (
								<div key={q.id} className="bg-gray-700 rounded-lg py-4 sm:py-12 px-4 sm:px-12 drop-shadow-lg">
									<div className="space-y-4">
										<p className="font-semibold text-lg">{q.question}</p>
										<ul className="list-inside space-y-2">
											{q.options.map((option, index) => (
												<li key={index}>{option}</li>
											))}
										</ul>

										<div className="flex justify-center py-4">
											<motion.button
												onClick={() => handleToggle(q.id)}
												className="cursor-pointer w-full bg-gray-500 hover:bg-gray-600 text-white font-medium px-6 py-3 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-emerald-300"
												initial={{ y: 20, opacity: 0.8 }}
												animate={{ y: 0, opacity: 1 }}
												transition={{ duration: 0.3 }}>
												{activeQuestion === q.id ? `Answer: ${q.answer}` : 'Reveal Answer'}
											</motion.button>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div></div>
			</div>
		</div>
	);
};
