import React from 'react';
import { motion } from 'framer-motion';

export const StockChartLoading = () => {
	const chartPath = 'M10 80 L30 60 L50 70 L70 50 L90 40 L110 60 L130 30';

	const points = [
		{ x: 10, y: 80 },
		{ x: 30, y: 60 },
		{ x: 50, y: 70 },
		{ x: 70, y: 50 },
		{ x: 90, y: 40 },
		{ x: 110, y: 60 },
		{ x: 130, y: 30 },
	];

	return (
		<div className="flex justify-center items-center min-h-screen bg-gray-900">
			<motion.svg width="200" height="120" viewBox="0 0 150 100" xmlns="http://www.w3.org/2000/svg" className="drop-shadow-lg">
				{/* Background Grid */}
				<motion.g opacity={0.2}>
					<line x1="0" y1="20" x2="150" y2="20" stroke="#fff" strokeWidth="0.5" />
					<line x1="0" y1="40" x2="150" y2="40" stroke="#fff" strokeWidth="0.5" />
					<line x1="0" y1="60" x2="150" y2="60" stroke="#fff" strokeWidth="0.5" />
					<line x1="0" y1="80" x2="150" y2="80" stroke="#fff" strokeWidth="0.5" />
					<line x1="0" y1="100" x2="150" y2="100" stroke="#fff" strokeWidth="0.5" />
					<line x1="0" y1="0" x2="0" y2="100" stroke="#fff" strokeWidth="0.5" />
				</motion.g>

				{/* Stock Market Line */}
				<motion.path
					d={chartPath}
					stroke="url(#lineGradient)"
					strokeWidth="2.5"
					fill="none"
					strokeLinecap="round"
					initial={{ pathLength: 0 }}
					animate={{ pathLength: 1 }}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						repeat: Infinity,
						repeatType: 'reverse',
					}}
				/>

				{/* Key Data Points with Animated Dots */}
				{points.map((point, index) => (
					<motion.circle
						key={index}
						cx={point.x}
						cy={point.y}
						r="3"
						fill="#4ADE80"
						initial={{ scale: 0 }}
						animate={{ scale: 1 }}
						transition={{
							duration: 0.4,
							delay: index * 0.2,
							repeat: Infinity,
							repeatType: 'reverse',
						}}
					/>
				))}

				{/* Gradient for Line */}
				<defs>
					<linearGradient id="lineGradient" x1="0" y1="0" x2="150" y2="100" gradientUnits="userSpaceOnUse">
						<stop stopColor="#22C55E" />
						<stop offset="1" stopColor="#4ADE80" />
					</linearGradient>
				</defs>
			</motion.svg>
		</div>
	);
};
