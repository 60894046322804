import React from 'react';

export const LibraryCover = ({ title, imageSrc }) => {
	return (
		<div className="audio-player bg-zinc-800 rounded-xl overflow-hidden shadow-lg">
			{imageSrc && (
				<div className="image-container">
					<img src={imageSrc} alt={title} className="w-full max-h-40 object-cover" />
				</div>
			)}
			<div className="controls p-4 text-white">
				{title && <h2 className="text-lg font-semibold">{title}</h2>}
				<p className="text-sm text-zinc-400">LVLUPINFINANCE</p>
			</div>
		</div>
	);
};
