// import packages
import React, { useState, memo } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

// import assets
import clean from '../../public/assets/modern_004.png';

// import data
import { episodes } from '../data/episodes.js';

// import components
import { Navbar } from '../components/navbar/index.jsx';
import { Footer } from '../components/footer/index.jsx';
import { PodcastPlayer } from '../components/AudioPlayer/index.jsx';
import { LibraryCover } from '../components/library_cover/index.jsx';
import { useUserValidation } from '../hooks/userValidation.jsx';
import { StockChartLoading } from '../components/stock_chart_loading/index.jsx';

export const ReportsPage = memo(() => {
	const { isVerified } = useUserValidation();
	const [toggleLearnMore, setToggleLearnMore] = useState({}); // Track toggle state per episode

	// Framer Motion animations
	const fadeIn = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeInOut' } },
	};

	const handleToggle = id => {
		setToggleLearnMore(prevState => ({
			...prevState,
			[id]: !prevState[id],
		}));
	};

	if (isVerified === null) return <StockChartLoading />; // Show loading state

	const renderLearnMore = episode => {
		const isOpen = toggleLearnMore[episode.id] || false; // Default to false if not set

		return isOpen ? (
			<div className="px-4 py-8">
				<h2 className="text-2xl font-bold mb-4">{episode.title}</h2>
				<p className="text-gray-600 mb-6">{episode.description}</p>
				<h3 className="text-lg font-semibold mb-2">Key Takeaways:</h3>
				<ul className="list-disc list-inside text-gray-700 mb-4">
					{episode.takeaways.map((takeaway, idx) => (
						<li key={idx}>{takeaway}</li>
					))}
				</ul>
				<div className="text-gray-700">
					<strong>Insights:</strong> {episode.insights}
				</div>
				<button className="mt-4 text-gray-900 underline logo font-semibold" onClick={() => handleToggle(episode.id)}>
					Show Less
				</button>
			</div>
		) : (
			<div className="py-4">
				<p className="text-gray-900 logo font-bold">{episode.title}</p>
				<button className="text-gray-900 underline logo font-normal" onClick={() => handleToggle(episode.id)}>
					Learn More
				</button>
			</div>
		);
	};

	const renderLinkOut = episode => {
		return (
			<div className="py-4">
				<p className="text-gray-900 logo font-bold">{episode.title}</p>
				<button className="text-gray-900 underline logo font-normal" onClick={() => window.location.replace(episode.link)}>
					Learn More
				</button>
			</div>
		);
	};

	return (
		<div className="bg-gray-100">
			{/* Navbar */}
			<Navbar />
			<Helmet>
				<title>Exclusive Financial Insights | lvlupinfinance</title>
				<meta
					name="description"
					content="Dive into our members-only content library for exclusive episodes breaking down SEC filings, key takeaways, and actionable insights for your financial journey."
				/>
				<meta name="keywords" content="finance, investing, SEC filings, exclusive content, financial growth, podcasts" />
			</Helmet>

			{/* Hero Section */}
			<div
				className="relative h-full py-40 flex items-center justify-center text-white px-4"
				style={{
					backgroundImage: `url(${clean})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundAttachment: window.innerWidth > 768 ? 'fixed' : 'scroll',
				}}>
				<div className="absolute inset-0 bg-gray-800/40"></div>
				<div className="relative z-10 text-center">
					<motion.h1 initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8 }} className="text-5xl font-bold mb-4">
						Educational Content Library
					</motion.h1>
					<motion.p
						initial={{ y: 20, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ duration: 0.8 }}
						className="text-lg max-w-2xl mx-auto px-2 subText tracking-wide">
						Welcome to our hub for educational content, where we aim to make finance accessible and engaging for everyone. This is our space to experiment and bring you
						valuable insights. Let us know what resonates with you so we can create content that truly empowers your financial journey.
					</motion.p>
				</div>
			</div>

			{/* Episodes Section */}
			<div className="py-4">
				<div className="max-w-6xl mx-auto px-2 sm:px-2 space-y-4 sm:space-y-0 grid grid-cols-1 sm:grid-cols-2">
					{episodes.map((episode, index) => (
						<motion.div
							className="sm:px-4 py-2"
							initial="hidden"
							whileInView="visible"
							variants={{
								hidden: { opacity: 0, scale: 0.95 },
								visible: { opacity: 1, scale: 1, transition: { delay: index * 0.2, duration: 0.5 } },
							}}
							viewport={{ once: true }}
							key={episode.id}>
							{/* Content Box */}
							<div className="bg-white rounded-xl drop-shadow-xl p-4">
								{episode.audioSrc === null ? <LibraryCover key={episode.id} {...episode} /> : <PodcastPlayer key={episode.id} {...episode} />}
								{episode.audioSrc === null ? renderLinkOut(episode) : renderLearnMore(episode, index !== 0 && !isVerified)}
							</div>
						</motion.div>
					))}
				</div>
			</div>

			{/* Footer */}
			<Footer />
		</div>
	);
});
