// import audio
import oklo_podcast from 'url:../../public/assets/oklo_podcast.mp3';
import asts_podcast from 'url:../../public/assets/asts_podcast.mp3';

// import images
import oklo_energy from '../../public/assets/future_clean_energy.png';
import satellite from '../../public/assets/satellite.png';
import oklo_001 from '../../public/assets/revised_oklo.png';
import formS1 from '../../public/assets/form_s_1.png';

export const episodes = [
	{
		id: 1,
		title: 'Oklo’s 10-Q Filing Breakdown',
		description: 'Breaking down Oklo’s latest 10-Q SEC filing.',
		takeaways: ['Revenue increased by 20% YoY.', 'New reactor project timeline updates.', 'Risks outlined in the 10-Q filing.'],
		insights: 'Monitor upcoming regulatory filings for approval status.',
		audioSrc: oklo_podcast,
		imageSrc: oklo_energy,
		podcastName: 'oklo_energy',
		link: '#',
	},
	{
		id: 2,
		title: `Exploring AST SpaceMobile's Latest 10-Q`,
		description: `Explore insights from AST SpaceMobile's latest 10-Q filing.`,
		takeaways: [
			'Global smartphone connectivity via space-based broadband.',
			'$189M cash, heavy R&D, potential fundraising.',
			'Technical challenges, regulations, SpaceX, OneWeb competition.',
		],
		insights: 'Global connectivity hinges on overcoming technical, financial, and competitive challenges.',
		audioSrc: asts_podcast,
		imageSrc: satellite,
		podcastName: 'asts_podcast',
		link: '#',
	},
	{
		id: 3, // Ensure the ID is unique
		title: 'Exploring OKLO Inc. Chart Patterns',
		description: 'A comprehensive breakdown of the latest technical analysis.',
		takeaways: ['Trend insights for the quarter.', 'Key support and resistance levels.', 'Potential breakout patterns.'],
		insights: 'Stay ahead by monitoring these critical price zones.',
		audioSrc: null,
		imageSrc: oklo_001,
		podcastName: null, // No podcast
		link: '/oklo-ta-q1-2025', // Direct link to the new TA page
	},
	{
		id: 4, // Ensure the ID is unique
		title: 'Introduction to Form S-1',
		description: '',
		takeaways: [''],
		insights: '',
		audioSrc: null,
		imageSrc: formS1,
		podcastName: null, // No podcast
		link: '/form-s-1', // Direct link to the new TA page
	},
	// Add more episodes as needed
];
