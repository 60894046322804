// import packages
import { motion } from 'motion/react';

// import components
import { Navbar } from '../components/navbar/index.jsx';
import { Footer } from '../components/footer/index.jsx';

export const NotAuthorizedPage = () => {
	return (
		<div className="bg-gray-800">
			<Navbar />
			<div className="my-auto py-24 mx-auto px-8 sm:px-64">
				<div className="space-y-8">
					{/* Headline */}
					<div className="text-center text-zinc-50">
						<h1 className="text-[32px] logo font-black tracking-wide">Almost there!</h1>
						<p className="text-[20px] mainText">This page is reserved for our members. Sign up now to unlock the exclusive perks waiting for you.</p>
					</div>

					{/* Credentials */}
					<div className="grid grid-cols-1 sm:grid-cols-2 space-y-4 sm:space-y-0">
						<div className="px-0 sm:px-4">
							<motion.button
								whileHover={{ scale: 1.02, fontWeight: 900 }}
								whileTap={{
									scale: 1.1,
									transition: { type: 'spring', stiffness: 300, damping: 15, delay: 0.05 },
								}}
								onClick={() => window.location.replace('/login')}
								className="rounded-xl bg-luif-cta-2 text-luif-bg px-4 py-2 text-[16px] logo uppercase font-semibold text-center w-full cursor-pointer">
								Login
							</motion.button>
						</div>
						<div className="px-0 sm:px-4">
							<motion.button
								whileHover={{ scale: 1.02, fontWeight: 900 }}
								whileTap={{
									scale: 1.1,
									transition: { type: 'spring', stiffness: 300, damping: 15, delay: 0.05 },
								}}
								onClick={() => window.location.replace('/signup')}
								className="rounded-xl bg-zinc-900 text-zinc-50 border border-zinc-50 px-4 py-2 text-[16px] logo uppercase font-semibold text-center w-full cursor-pointer">
								Sign Up Now
							</motion.button>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
